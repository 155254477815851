<!--
 * @Author: hugo 1634765756@qq.com
 * @Date: 2022-05-01 15:55:53
 * @LastEditors: hugo
 * @LastEditTime: 2022-06-01 09:38:05
 * @FilePath: /miaohang/src/components/Group/Aside/PersonalCenter.vue
 * @Description: file content
-->

<template>
  <div class="group_aside_task">
    <div class="wrapper">
      <router-link :to="{name: 'personal_center_data'}" class="item" :class="{on: $route.name == 'personal_center_data'}">
        <img src="@/assets/images/personal_center/personal.png" alt="" class="icon">
        <div class="text">个人资料</div>
      </router-link>
      <router-link :to="{name: 'personal_center_password'}" class="item" :class="{on: $route.name == 'personal_center_password'}">
        <img src="@/assets/images/personal_center/password.png" alt="" class="icon">
        <div class="text">修改密码</div>
      </router-link>
      <router-link :to="{name: 'personal_center_activation_code'}" class="item" :class="{on: $route.name == 'personal_center_activation_code'}">
        <img src="@/assets/images/personal_center/activation_code.png" alt="" class="icon">
        <div class="text">产品激活</div>
      </router-link>
      <router-link :to="{name: 'personal_center_vip_and_consumption'}" class="item"
        :class="{on: $route.name == 'personal_center_vip_and_consumption'}"
      >
        <img src="@/assets/images/personal_center/vip.png" alt="" class="icon">
        <div class="text">会员与消费</div>
      </router-link>
      <router-link :to="{name: 'personal_center_gift_card'}" class="item"
        :class="{on: $route.name == 'personal_center_gift_card'}"
      >
        <img src="@/assets/images/personal_center/gift_card.png" alt="" class="icon">
        <div class="text">礼品卡</div>
      </router-link>
      <div @click="$api.common_logout()" class="item">
        <img src="@/assets/images/personal_center/logout.png" alt="" class="icon">
        <div class="text">退出登录</div>
      </div>
      <div class="item"  v-if="0">
        <img src="@/assets/images/personal_center/track.png" alt="" class="icon">
        <div class="text">成长轨迹</div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  components: {
   
  },
  data() {
    return {
     
    };
  },
  computed: {
    
  },
  watch: {
    '$route':{
      handler: function(){},
      deep: true,
    }
  },
  methods: {
    
    
  },
  async mounted() {
   
  },
};
</script>
<style lang="scss" scoped>
.group_aside_task {
  position: relative;
  z-index: 3;
  width: 250px;
  height: 100%;
  overflow-y: auto;
  @include hidescrollbar;
  position: relative;
  .wrapper {
    overflow-x: visible;
    width: 250px;
    // height: 100%;
  }
  .item{
    @include flex;
    @include bbox;
    @include transition;
    height: 66px;
    cursor: pointer;
    padding-left: 29px;
    &:hover, &.on{
      background-color: #ededed;
    }
    .icon{
      margin-right: 15px;
      display: block;
      width: 24px;
      height: 22px;
    }
    .text{
      font-size:14px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
    }
  }
}
</style>