var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "group_aside_task" }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c(
          "router-link",
          {
            staticClass: "item",
            class: { on: _vm.$route.name == "personal_center_data" },
            attrs: { to: { name: "personal_center_data" } },
          },
          [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("@/assets/images/personal_center/personal.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "text" }, [_vm._v("个人资料")]),
          ]
        ),
        _c(
          "router-link",
          {
            staticClass: "item",
            class: { on: _vm.$route.name == "personal_center_password" },
            attrs: { to: { name: "personal_center_password" } },
          },
          [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("@/assets/images/personal_center/password.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "text" }, [_vm._v("修改密码")]),
          ]
        ),
        _c(
          "router-link",
          {
            staticClass: "item",
            class: { on: _vm.$route.name == "personal_center_activation_code" },
            attrs: { to: { name: "personal_center_activation_code" } },
          },
          [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("@/assets/images/personal_center/activation_code.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "text" }, [_vm._v("产品激活")]),
          ]
        ),
        _c(
          "router-link",
          {
            staticClass: "item",
            class: {
              on: _vm.$route.name == "personal_center_vip_and_consumption",
            },
            attrs: { to: { name: "personal_center_vip_and_consumption" } },
          },
          [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("@/assets/images/personal_center/vip.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "text" }, [_vm._v("会员与消费")]),
          ]
        ),
        _c(
          "router-link",
          {
            staticClass: "item",
            class: { on: _vm.$route.name == "personal_center_gift_card" },
            attrs: { to: { name: "personal_center_gift_card" } },
          },
          [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("@/assets/images/personal_center/gift_card.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "text" }, [_vm._v("礼品卡")]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "item",
            on: {
              click: function ($event) {
                return _vm.$api.common_logout()
              },
            },
          },
          [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("@/assets/images/personal_center/logout.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "text" }, [_vm._v("退出登录")]),
          ]
        ),
        0
          ? _c("div", { staticClass: "item" }, [
              _c("img", {
                staticClass: "icon",
                attrs: {
                  src: require("@/assets/images/personal_center/track.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "text" }, [_vm._v("成长轨迹")]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }